<template>
    <div ref="container">
        <v-card :loading="loading" class="pa-2">
            <v-card-title ref="title">
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    {{ invoice.name }}
                    <span
                        v-if="invoice.projectId"
                        class="font-weight-black text-uppercase"
                    >
                        / PROJECT: {{ project.name }}</span
                    >
                </h2>
                <v-btn
                    v-if="invoice.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="px-4">
                <v-row ref="details_1">
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            v-if="invoice.createdBy && invoice.createdBy.name"
                            v-model="invoice.createdBy.name"
                            outline
                            prefix="Created By: "
                            hide-details
                            prepend-icon="mdi-account-group-outline"
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            hide-details
                            v-model="invoice.date"
                            prepend-icon="mdi-calendar"
                            prefix="Created On: "
                            outline
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <v-text-field
                            outline
                            v-model="invoice.type"
                            prepend-icon="mdi-table-column"
                            prefix="Type: "
                            hide-details
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <v-text-field
                            outline
                            v-model="invoice.region"
                            prepend-icon="mdi-earth"
                            prefix="Region: "
                            hide-details
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row ref="details_2">
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            hide-details
                            v-model="project.currency"
                            prepend-icon="mdi-cash"
                            prefix="Currency: "
                            outline
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            outline
                            style="color:blue;"
                            v-model="invoice.invoiceNumber"
                            prepend-icon="mdi-receipt-text-outline"
                            prefix="Invoice No: "
                            :disabled="
                                invoice.status == 'billed' ||
                                    invoice.status == 'approved' ||
                                    invoice.status == 'closed'
                            "
                            :rules="[rules.required]"
                        />
                    </v-col>
                    <v-col
                        v-if="invoice.status == 'generating'"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="px-2 pb-2 pt-2"
                    >
                        <v-file-input
                            prefix="Upload Invoice"
                            v-model="invoice.attached"
                            :rules="[rules.required]"
                            required
                            prepend-icon="mdi-upload"
                            accept=".pdf"
                            style="color:blue;"
                        ></v-file-input>
                        <v-chip
                            v-if="
                                invoice.status == 'billed' ||
                                    invoice.status == 'approved' ||
                                    invoice.status == 'closed'
                            "
                            class="mt-1 ml-1"
                            @click="loadFile(invoice.attached)"
                        >
                            <v-icon class="mr-1">mdi-paperclip</v-icon>
                            <span>{{ invoice.attached }}</span>
                        </v-chip>
                    </v-col>
                    <v-col
                        v-if="invoice.status == 'closed'"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="d-flex flex-wrap pa-2 pt-2"
                    >
                        <div class="d-flex mr-2">
                            <h3 class="mt-5">Invoice:</h3>
                            <v-chip
                                v-if="invoice.attached"
                                class="mt-4 ml-1 pr-10"
                                style="max-width: 200px"
                                @click="loadFile(invoice.attached)"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span class="col-12 text-truncate">{{
                                    invoice.attached
                                }}</span>
                            </v-chip>
                        </div>
                        <div class="d-flex">
                            <h3 class="mt-5">Credit note:</h3>
                            <v-chip
                                v-if="invoice.credit"
                                class="mt-4 ml-1 pr-10"
                                style="max-width: 200px"
                                @click="loadFile(invoice.credit)"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span class="col-12 text-truncate">{{
                                    invoice.credit
                                }}</span>
                            </v-chip>
                        </div>
                    </v-col>
                    <v-col
                        v-if="invoice.status == 'credit'"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="pa-2 pt-2"
                    >
                        <v-file-input
                            prefix="Upload Credit Note"
                            v-model="credit"
                            :rules="[rules.required]"
                            required
                            prepend-icon="mdi-upload"
                            accept=".pdf"
                            style="color:blue;"
                            :clearable="false"
                        ></v-file-input>
                        <v-chip
                            v-if="invoice.status == 'credit' && invoice.credit"
                            class="mt-1 ml-1"
                            @click="loadFile(invoice.credit)"
                        >
                            <v-icon class="mr-1">mdi-paperclip</v-icon>
                            <span>{{ invoice.credit }}</span>
                        </v-chip>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-textarea
                            hide-details
                            rows="4"
                            prepend-icon="mdi-text"
                            class="text-body-2 pt-2"
                            label="Notes: "
                            outline
                            v-model="invoice.notes"
                            readonly
                            style="line-height: 1 !important;"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row ref="details_3">
                    <v-col cols="12" xl="3" lg="3" md="3" class="pt-0">
                        <div class="d-flex my-2">
                            <div class="d-flex align-center">
                                <strong>Attachments: </strong>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <v-btn
                                    color="grey lighten-3"
                                    elevation="0"
                                    x-small
                                    class="ma-1 text-capitalize grey--text text--darken-1  "
                                    v-for="(file, i) in invoice.attachments"
                                    :key="i"
                                >
                                    <span @click="openFileAttached(file)">{{
                                        file
                                    }}</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    class="quote_tables mt-8"
                    :style="`height: ${height}px`"
                >
                    <v-col cols="12" class="mb-6">
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        `${item.name}${
                                            item.project
                                                ? ' - ' + item.project.name
                                                : ''
                                        }`
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.type`]="{}">
                                <p class="my-0 font-weight-bold">
                                    Proforma Features
                                </p>
                            </template>
                            <template v-slot:[`item.pricePerUnit`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ numberFormat(item.proformaValue) }}
                                </p>
                            </template>
                            <template v-slot:[`item.quantity`]="{}">
                                <p class="my-0 font-weight-bold">
                                    {{ 1 }}
                                </p>
                            </template>
                            <template v-slot:[`item.currentInvoice`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ numberFormat(item.proformaValue) }}
                                </p>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    style="background-color: #eeeeee"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black ml-4">TOTALS:</p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT PRICE:
                            {{ numberFormat(invoice.proformaValue) }}
                        </p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT BILLED:
                            {{ numberFormat(invoice.totalInvoiced || 0) }}
                        </p>
                    </v-col>
                    <v-spacer />
                    <v-col
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        class="d-flex justify-space-between"
                    >
                        <p class="my-2 font-weight-black">Qty: {{ 1 }}</p>
                        <p class="my-2 font-weight-black mr-4">
                            Current Invoice:
                            {{ numberFormat(invoice.proformaValue) }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions">
                <v-btn
                    v-if="invoice.status == 'generating'"
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="openRejectForm"
                >
                    REJECT
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="invoice.status == 'generating'"
                    :loading="loading"
                    rounded
                    color="#27BC4F"
                    class="mb-4 mr-2 white--text"
                    @click="generate"
                    :disabled="!invoice.attached || !invoice.invoiceNumber"
                >
                    GENERATE
                </v-btn>
                <v-btn
                    v-if="invoice.status == 'credit'"
                    :disabled="!this.invoice.credit"
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="openCloseInvoice = true"
                >
                    CLOSE
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--Reject Work Orders-->
        <v-dialog
            v-model="rejectForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="rejectInvoiceLoading" v-if="rejectForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this
                            invoice
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectForm">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectInvoice"
                        text
                        color="error"
                        :loading="rejectInvoiceLoading"
                        :disabled="!valid"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--CLOSE INVOICE-->
        <v-dialog
            v-model="openCloseInvoice"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openCloseInvoice">
                <v-card-title class="text-h5">CLOSE INVOICE</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Are you sure you want to close this invoice?
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openCloseInvoice = false"
                    >
                        CANCEL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeInvoice"
                        color="primary"
                        :loading="loading"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedInvoicesTable
                v-if="rejectionTable"
                :items="invoice.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
        <!--Error Alert-->
        <Errors />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { loadImage } from '@/helpers/imageHandler.js'

export default {
    name: 'ProformaFeaturesGenerating',
    props: {
        project: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        inProcess: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        RejectedInvoicesTable: () =>
            import('@/components/Invoicing/RejectedInvoicesTable'),
    },
    data: () => ({
        openCloseInvoice: false,
        rejectedNotes: null,
        valid: false,
        loading: true,
        rules: {
            required: v => !!v || 'The value is required',
        },
        regions: ['international', 'local'],
        height: 0,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEM',
                value: 'name',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                sortable: false,
            },
            {
                text: 'UNIT PRICE',
                value: 'pricePerUnit',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CURRENT INVOICE',
                value: 'currentInvoice',
                sortable: false,
                align: 'center',
            },
        ],
        rejectForm: false,
        companyId: JSON.parse(localStorage.getItem('company')),
        rejectInvoiceLoading: false,
        credit: null,
        rejectionTable: false,
        items: [],
    }),
    inject: {
        addInvoice: {
            from: 'addInvoice',
            default() {
                return null
            },
        },
    },
    mounted() {
        try {
            this.loading = true
            this.invoice.date = this.formatDate(this.invoice.createdOn._seconds)
            this.invoice.notes = `Address: ${this.invoice.address}
Phone: ${this.invoice.phone}
P.A: ${this.invoice.tariffItem}
Description: ${this.invoice.description}
            `
            this.items = [this.invoice]
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        async credit() {
            try {
                this.loading = true
                await saveFile(
                    this.credit,
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`
                )
                this.invoice.credit = this.credit.name
                const invoice = await API.uploadCreditNote({
                    id: this.invoice.id,
                    projectId: this.invoice.proformaProject,
                    credit: this.invoice.credit,
                })
                this.$emit('replaceInvoice', invoice)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async closeInvoice() {
            try {
                this.loading = true
                const invoice = await API.closeInvoice({
                    projectId: this.invoice.proformaProject,
                    id: this.invoice.id,
                })
                this.$emit('replaceInvoice', invoice)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadFile(file) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`,
                    file
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        numberFormat(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: 2,
            }).format(number)
        },
        async generate() {
            try {
                this.loading = true
                //save file
                if (!this.invoice.attached || !this.invoice.attached.name) {
                    this.setErrorItems({
                        source: this.$options.name,
                        message: 'file not found',
                    })
                    return null
                }
                await saveFile(
                    this.invoice.attached,
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`
                )
                // generate invoice
                const invoice = await API.generateInvoice({
                    id: this.invoice.id,
                    projectId: this.invoice.proformaProject,
                    attached: this.invoice.attached.name,
                    invoiceNumber: this.invoice.invoiceNumber,
                    notificationId: '2o3QIGSpJM5Zb1Fpd8jW',
                })
                this.$emit('generate', invoice)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async rejectInvoice() {
            try {
                this.rejectInvoiceLoading = true
                const invoice = await API.rejectInvoice({
                    id: this.invoice.id,
                    projectId: this.invoice.proformaProject,
                    notes: this.rejectedNotes,
                    notificationId: 'oEZbNBsm7NuoohIWRRKo',
                })
                this.rejectForm = false
                this.$emit('removeInvoice', invoice)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.rejectInvoiceLoading = false
            }
        },
        closeRejectForm() {
            this.rejectForm = false
        },
        openRejectForm() {
            this.rejectForm = true
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
                totals: { clientHeight: totalsHeight },
                details_1: { clientHeight: details_1Height },
                details_2: { clientHeight: details_2Height },
                details_3: { clientHeight: details_3Height },
                actions: { clientHeight: actionsHeight },
            } = this.$refs
            this.height =
                window.innerHeight -
                titleHeight -
                totalsHeight -
                details_1Height -
                details_2Height -
                details_3Height -
                actionsHeight -
                70
        },
        close() {
            this.$emit('close')
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style>
.quote_tables {
    width: 100%;
    overflow: scroll;
}
</style>
